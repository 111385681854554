import React from 'react'
import refreshIcon from "../assets/images/refreshIcon.png";
import ReactTooltip from "react-tooltip";
import './responsive.css';
import sltblogo from "../assets/images/sltb_logo.png"

const AllPageNavBar= ()=> {

    const RefreshLocation = (e)=>{
        window.location.reload();
        }

  return (
    <div>
        <center>
        <div id='maindiv'>
                <div>
                    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                    <img src={sltblogo} style={{height:"10%" , width:"10%"}}/>
                    {/* <a className="navbar-brand" href="/">SLTB</a> */}
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarText">
                        <ul className="navbar-nav mr-auto">
                        {/* <li className="nav-item active">
                            <a className="nav-link" href="/">Home</a>
                        </li>
                        <li className="nav-item active">
                            <a className="nav-link" href="/sltb/bus-tracker/all">View All Buses</a>
                        </li> */}
                        </ul>
                        <span className="navbar-text">
                            {/* <button className='btn btn-success' data-tip data-for="refreshTip" onClick={(e)=>RefreshLocation(e)} style={{width:"80%"}}>
                                <img src={refreshIcon} style={{width:"50%"}}/>
                            </button> */}
                            <a className="navbar-brand" href="/"><b>HotLine :- 1315</b></a>
                        </span>
                    </div>
                    </nav>
                </div>
        </div>
        </center> 
    </div>
  )
}

export default AllPageNavBar