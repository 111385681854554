import React, { useState , useEffect} from 'react'
import {useNavigate} from "react-router-dom";
import HomeImage from "../assets/images/homeImage.jpg"
import Footer from './Footer';
import NavBar from './NavBar';
import './responsive.css';

const Home = () => {

    const navigate = useNavigate();
    const [ID, setID] = useState("");


    const ChangeID = (e)=>{
      e.preventDefault();
      setID(e.target.value);
    }
  
    const searchBus = (e)=>{
        localStorage.setItem("id",ID);
        navigate(`/track/${ID}`);
    }

    // const [width, setwidth] = useState("80%");
    // const [height, setheight] = useState("100%");

    // useEffect(()=>{
    //   if(window.innerWidth < "500px")
    //   {
    //     setwidth(window.innerWidth);
    //   }
    //   if(window.innerWidth < "800px")
    //   {
    //     setheight(window.innerHeight);
    //   }
    // },[])

  return (
    <div >
      <NavBar/>
      <center>
        <div id="maindiv">
            <div style={{marginTop:"30px"}}>
                    <label style={{fontSize:"35px",color:"purple"}}><b>SLTB Tracker</b></label>
            </div>
            <br/><br/>
              <div >
                <img src={HomeImage} style={{width:"100%"}}/>
              </div>
            <br/>
            <div>    
                <div className="row">
                  <div className="col-sm-6">
                      <div>
                          <p style={{fontSize:"20px", color:"purple"}}>
                            SLTB Tracker is a location tracking web application. That you can 
                            use to find the current location of the Buses by searchig the  
                            location ID that provided or view all active buses.
                          </p>
                      </div>
                  </div>
                  <div className="col-sm-6">
                      <label style={{fontSize:"20px",color:"blue"}}>Enter Location ID to see Location</label>
                      <br/><br/>
                      <input 
                      className='input'
                      type="text"
                      name="ID"
                      value={ID}
                      placeholder="enter Reference"
                      onChange={(e)=>ChangeID(e)}
                      />
                      <br/><br/>
                    <button className='btn btn-success' onClick={(e)=>searchBus(e)}>Search</button>
                  </div>
                </div>
            </div>
            <br/><br/>
        </div>
      </center>
      <Footer/>
    </div>
  )
}

export default Home