import axios from "axios";
import apiBaseURL from "../config/apiConfig.json";

// "apiBaseURL":"http://173.82.120.246:9595/",

const createLocationURL = apiBaseURL?.apiBaseURL + "location/create";
const updateLocationURL = apiBaseURL?.apiBaseURL + "location/update-by-location-id/";
const getAllLocationURL = apiBaseURL?.apiBaseURL + "location/get-all";
const getByDriverIdLocationURL = apiBaseURL?.apiBaseURL + "location/get-by-location-id/";


export async function createNewLocation(data){
    const alldata ={
        driver_id:data?.driver_id,
        bus_num:data?.bus_num,
        lat:data?.lat,
        long:data?.long
    }

    return axios.post(createLocationURL,alldata);
}

export async function getAllLocations(){
   return axios.get(getAllLocationURL);
}

export async function getLocationByDriverID(id){
    return axios.get(getByDriverIdLocationURL + id);
}

export async function updateLocationByDriverId(id,data){
    const alldata ={
        lat:data?.lat,
        long:data?.long
    }

    return axios.put(updateLocationURL + id,alldata);
}