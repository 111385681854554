import React from 'react'
import './responsive.css';

const Footer = ()=> {
  return (
    <div>
        <center>
        <div id='maindiv'>
        <footer className="bg-dark text-center text-white">
   
            <div className="text-center p-3" style={{backgroundColor: "rgba(0, 0, 0, 0.2)"}}>
            SLTB Tracker © 2022 Hands Global Holdings (Pvt) Ltd. All rights reserved.
                {/* <a className="text-white" href="https://nexmatrixlab.com/" style={{textDecoration:"none"}}></a> */}
            </div>

        </footer>
        </div>
        </center>
    </div>
  )
}

export default Footer;