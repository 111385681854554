import React, { useState,useEffect } from 'react'
import {GoogleMap, useJsApiLoader, Marker , InfoWindow,Circle , Polyline} from '@react-google-maps/api';
import { getAllLocations } from '../services/locationServices';
import {
    Label
} from "reactstrap"
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import './responsive.css';
import { useNavigate } from 'react-router-dom';
import AllPageNavBar from './AllPageNavbar';
import eseatLogo from "../assets/images/image.jpeg"

const DefaultZoom = 8;

const  ViewAllBuses = () => {

  const navigate = useNavigate();


  const [locationArray, setlocationArray] = useState([]);
  const [load, setload] = useState(false);
  const [center, setCenter] = useState({
    lat: 7.2906,
    lng: 80.6337
   });

  const [libraries] = useState(['places']);
  const {isLoaded} = useJsApiLoader({
  googleMapsApiKey: "AIzaSyAkBhTU6Tc8FNdu64ZRG4rPm2bin7H7OOI",
  libraries: libraries,
  })

  const containerStyle = {
    width: '100%',
    height: '800px'
  };

  const [openInfoWindow, setopenInfoWindow] = useState([]);
  const [openedIndex, setopenedIndex] = useState(-1);

  const openInfoWindowToShow = (index,value) => {
    console.log("selected marker vales",index,value)
    const tempArray = openInfoWindow;
    tempArray[index].status = value;
    setopenInfoWindow(tempArray);
    setopenedIndex(index);
  }

  const getLocationData = async ()=>{
    try {
      const data = await getAllLocations();
      console.log("data",data);
      var arrayofLocations= [];
      var arrayofinfoWindows = [];
      data?.data?.data.map((item)=>{
        if(item?.status === 1 || item?.status === 2)
        {
          var onemarker = {
            name:item?.bus_num,
            location:{
              lat:parseFloat(item?.latitude),
              lng:parseFloat(item?.longitude)
            },
            driver_id:item?.driver_id,
            location_id:item?.location_id,
            bus_num:item?.bus_num,
            status:item?.status,
          }
          arrayofLocations.push(onemarker);
   
          var infowindowopen = {
            status:false
          }
          arrayofinfoWindows.push(infowindowopen);
        }
      });
      setlocationArray(arrayofLocations);
      setopenInfoWindow(arrayofinfoWindows);
      setload(true);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(()=>{
    setInterval(function() {
      getLocationData();
    }, 15000);
  },[])

  useEffect(() => {
    getLocationData();
  }, [])





   const options = {
    strokeColor: '#1344e8',
    strokeOpacity: 0.8,
    strokeWeight: 1,
    fillColor: '#1344e8',
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 300,
    zIndex: 1
  }

  const seeBus = (id)=>{
    navigate("/track/"+id);
  }



  return (
    <div>
      <AllPageNavBar/>
          <center>
          <div id='maindiv'>
            <br/>
                
            <div>
                  <img src={eseatLogo} style={{width:"25%",float:"left", marginBottom:"10px" , marginTop:"-10px" , marginLeft:"-10px"}}/>
                  <Label style={{fontSize:"25px",color:"black"  , float:"right"}}><b>As of: {moment(new Date()).format(" MM-DD-YYYY ")} {moment(new Date()).format(" h:mm a ")}</b></Label>     
            </div>
                
            <br/>
            {load ? <div >
                    {isLoaded && <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={DefaultZoom}
                        options={{
                          streetViewControl: false,
                          mapTypeControl: false,
                        }} 
                        defaultZoom={DefaultZoom}  
                      >
                        {locationArray.map((item,index)=>{
                          //console.log("item",item);
                          return(
                            <Marker
                              animation={2}
                              title={item?.name}
                              position={item?.location}  
                              onClick={() => {openInfoWindowToShow(index,true)}}    
                              icon={
                                      {
                                       url: (require('../assets/images/busicon2.png')),
                                       scale: 5,
                                       strokeColor: "#FFFFFF",                                     
                                     }
                                    }                                                   
                              />
                          )
                        })
                        }
   
                        {openInfoWindow[openedIndex]?.status ? (
                          <InfoWindow
                            animation={2}
                            position={locationArray[openedIndex]?.location}
                            onCloseClick={()=>openInfoWindowToShow(openedIndex,false)}                                         
                            >
                              <div>
                                  <br/>
                                  <label><b>{locationArray[openedIndex]?.name}</b></label>
                                  <br/>
                                  <label><b>{locationArray[openedIndex]?.status == 1 ? "On going" :locationArray[openedIndex]?.status == 2 ? "stopped" :  ""}</b></label>
                                  <br/>
                                  {/* <button 
                                    onClick={(e)=>seeBus(locationArray[openedIndex]?.location_id)}
                                    className="btn btn-primary"
                                  >
                                    See Bus Route
                                    </button> */}
                              </div>
                            </InfoWindow>
                            ) 
                            :
                            null
                            } 
                      </GoogleMap> }      
            </div>
            :
            <div>       
                  <center>  
                    <label style={{fontSize:"30px",color:"green"}}>Please wait...</label>
                    &nbsp;&nbsp;&nbsp;
                    <Spinner animation="border" variant="success" />
                  </center> 
            </div>}
        </div>
        </center>  
  
    </div>
  )
}

export default ViewAllBuses;