import React, { useState,useEffect } from 'react'
import NewMap from './NewMap'
import "./responsive.css"
import {
    Modal,
    ModalBody,
    ModalHeader,
    Label
} from "reactstrap";
import { getLocationByDriverID } from '../services/locationServices';
import moment from 'moment';
import { useParams} from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import MapViewer from './MapViewer';

function MapComponent() {
    

  const {id} = useParams();

  const [locationArray, setlocationArray] = useState([]);
  const [bus_num, setbus_num] = useState("")
  const [load, setload] = useState(false);
  const [location, setlocation] = useState({
    lat: 0,
    lng: 0
   });
   const [AllLocations, setAllLocations] = useState([]);

  const getLocationData = async (id)=>{
    try {
      const data = await getLocationByDriverID(id);
      console.log("data",data);
      if(data?.data?.status == 1)
      {
        setlocation({ 
            lat:parseFloat(data?.data?.data[0].latitude),
            lng:parseFloat(data?.data?.data[0].longitude)
        });
        setbus_num(data?.data?.data[0].bus_num);
        setload(true);

        //creating array with locations to pass to map
        var newElement = {
          lat:parseFloat(data?.data?.data[0].latitude),
          lng:parseFloat(data?.data?.data[0].longitude)
        };
        setAllLocations(oldArray => [...oldArray, newElement]);
      
      }
      else
      {
        setlocation({ 
          lat: 6.9271,
          lng: 79.8612
      });
        setload(false);
      }

    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
   console.warn("All location array",AllLocations);
  },[AllLocations])

   //handle show user location details
   const [showUserLocation, setshowUserLocation] = useState(false);
   const [firstLoad, setfirstLoad] = useState(localStorage.getItem("firstload") === null ?  true : localStorage.getItem("firstload") ? false : true);  
 
   const handleShowUserLocation = (e,value)=>{
     e.preventDefault()
     setshowUserLocation(value);
     localStorage.setItem("firstload",true);
     setfirstLoad(false);
   }

   //current user location function

   const [userLocation, setuserLocation] = useState({
    lat: 0,
    lng: 0
   });

   const getCurrentUserLocation= ()=>{ 
    //console.error('Geolocation executed');
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            console.log("permission",result.state);
            //If granted then you can directly call your function here
            navigator.geolocation.getCurrentPosition((position) => {
              setuserLocation({ 
                    lat:parseFloat(position.coords.latitude),
                    lng:parseFloat(position.coords.longitude)
                });
               // console.log("goe locations",position);
            }, () => {
              console.error('Unable to retrieve your location');
            });
          } else if (result.state === "prompt") {
            console.log("permission",result.state);
          } else if (result.state === "denied") {
            //If denied then you have to show instructions to enable location
            console.log("permission",result.state);
          }
          result.onchange = function () {
            console.log("permission",result.state);
          };
        });
    } else {
      alert("Sorry Location Not available!");
    }
   }

   const executeOrder = ()=>{
    if(showUserLocation) 
    { 
      getCurrentUserLocation();
     // console.log("param data ",id);
      getLocationData(id);
    } 
    else
    {
      //console.log("param data ",id);
      getLocationData(id);
    }
   }

   useEffect(()=>{
    executeOrder();
  },[showUserLocation])

  useEffect(()=>{
    console.log("DB fetched locations",location)
  },[location])


  useEffect(()=>{
    setInterval(function() {
      getLocationData(id);
    }, 15000);
  },[load])



  const makelocationArray = ()=>{
    var array = [
      {
        name:bus_num,
        location:{
          lat:location?.lat,
          lng:location?.lng
        },
        status:0
      },
      {
        name:"Mylocation",
        location:{
          lat:userLocation?.lat,
          lng:userLocation?.lng
        },
        status:1
      }
    ]
    setlocationArray(array);
 }

 useEffect(() => {
    makelocationArray();
    //console.log("final location array data",locationArray);
 }, [bus_num,location,userLocation])




  return (
    <div>
        <center>
            <div id='maindiv'>
            <br/>
                
                <div>
                      <Label style={{fontSize:"25px",color:"black" , float:"left"}}><b>Vehicle Number : {bus_num}</b> </Label>
                      <Label style={{fontSize:"25px",color:"black"  , float:"right"}}><b>As of: {moment(new Date()).format(" MM-DD-YYYY ")} {moment(new Date()).format(" h:mm a ")}</b></Label>     
                </div>
                    
                <br/>
                <br/>
                <br/>
                {load?
                <div>
                    <NewMap AllLocations={AllLocations} userLocation={userLocation}/>
                    {/* <MapViewer/> */}
                </div>
                :
                <div>       
                    <center>  
                        <label style={{fontSize:"30px",color:"green"}}>Please wait...</label>
                        &nbsp;&nbsp;&nbsp;
                        <Spinner animation="border" variant="success" />
                    </center> 
                </div>}
            </div>
        </center>


        <Modal
          isOpen={firstLoad}
          className="modal-dialog-centered"
          fade={true}
          backdrop={true}
        >
          <ModalHeader
            toggle={() => {
              setfirstLoad(false);
            }}
          >
            <Label>Enter Details</Label>
          </ModalHeader>
          <ModalBody>
            <div >
                    <center>
                    <label style={{fontSize:"30px",color:"purple"}}>Want to show your location on map?</label>
                    <br/><br/>
                    <button className='btn btn-success' style={{width:"100px",height:"50px"}} onClick={(e)=>handleShowUserLocation(e,true)}>Yes</button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button className='btn btn-danger'style={{width:"100px",height:"50px"}} onClick={(e)=>handleShowUserLocation(e,false)}>No</button>
                    </center>
                </div>
          </ModalBody>
        </Modal>

    </div>
  )
}

export default MapComponent