
import React from 'react';
import { withGoogleMap, withScriptjs, GoogleMap, Polyline, Marker ,InfoWindow  } from 'react-google-maps'
import {Circle} from '@react-google-maps/api';
import { getAllLocations } from '../services/locationServices';
import {
    Label
} from "reactstrap"
import moment from 'moment';
import './responsive.css';

class ViewAllbusesClassMap extends React.Component {

  // constructor(props) {
  //   super(props);
  //   this.getCurrentUserLocation = this.getCurrentUserLocation.bind(this);
  // }

  state = {
    AllLocations:[],
    firstTime:true,
    load:false,
    openLocationInfo:false,
  }



  openLocationInfoDetails = ()=>{
    this.setState({openLocationInfo:true});
  }

  closeLocationInfoDetails = ()=>{
    this.setState({openLocationInfo:false});
  }



  velocity = 100
  initialDate = new Date()

  getDistance = () => {
    // seconds between when the component loaded and now
    const differentInTime = (new Date() - this.initialDate) / 1000 // pass to seconds
    return differentInTime * this.velocity // d = v*t -- thanks Newton!
  }

   getLocationData = async () => {
    try {
      const data = await getAllLocations();
      console.log("data",data);
      if(data?.data?.msg == "success")
      {
        var AllLocations = [];
          for(var i=0;i<data?.data?.data?.length;i++)
          {
            //new paths
            var newPath = [];
            var locat = [];
            if(this.state.AllLocations[i]?.currentLocation?.lat != data?.data?.data[i]?.latitude)
              {
                  locat.push({ 
                      lat:parseFloat(data?.data?.data[i]?.latitude),
                      lng:parseFloat(data?.data?.data[i]?.longitude),
                  });
  
              }
            var path = newPath.concat(locat);


            //new current location
            var curenntloc;
            if(this.state.AllLocations[i]?.currentLocation?.lat != data?.data?.data[i]?.latitude)
              {
                var curenntloc = {
                  lat:parseFloat(data?.data?.data[i]?.latitude),
                  lng:parseFloat(data?.data?.data[i]?.longitude),
                };
              }
            var currentLocation = curenntloc;

            //new location
            var location;
            var loc = {
              lat:parseFloat(data?.data?.data[i]?.latitude),
              lng:parseFloat(data?.data?.data[i]?.longitude),
            };
            var location = loc;


            //new bus num
            var bus_num = this.state.AllLocations[i]?.bus_num;
            var bus = data?.data?.data[i]?.bus_num;
            var bus_num = bus;


            //new driver id
            var driver_id = this.state.AllLocations[i]?.driver_id;
            var driver = data?.data?.data[i]?.driver_id;
            var driver_id = driver;


            //new location id
            var location_id = this.state.AllLocations[i]?.location_id;
            var locationID = data?.data?.data[i]?.location_id;
            var location_id = locationID;


            AllLocations.push({
              progress:[],
              path:path,
              currentLocation:currentLocation,
              location:location,
              bus_num:bus_num,
              driver_id:driver_id,
              location_id:location_id,
            });
          }
        if(this.state.firstTime)
        {
          var AllLocations = [];
          for(var i=0;i<data?.data?.data?.length;i++)
          {
            //new paths
            var newPath = [];
            var locat = [];
            if(this.state.AllLocations[i]?.currentLocation?.lat != data?.data?.data[i]?.latitude)
              {
                  locat.push({ 
                      lat:parseFloat(data?.data?.data[i]?.latitude),
                      lng:parseFloat(data?.data?.data[i]?.longitude),
                  });
  
              }
            var path = newPath.concat(locat);


            //new current location
            var curenntloc;
            if(this.state.AllLocations[i]?.currentLocation?.lat != data?.data?.data[i]?.latitude)
              {
                var curenntloc = {
                  lat:parseFloat(data?.data?.data[i]?.latitude),
                  lng:parseFloat(data?.data?.data[i]?.longitude),
                };
              }
            var currentLocation = curenntloc;

            //new location
            var location;
            var loc = {
              lat:parseFloat(data?.data?.data[i]?.latitude),
              lng:parseFloat(data?.data?.data[i]?.longitude),
            };
            var location = loc;


            //new bus num
            var bus_num = this.state.AllLocations[i]?.bus_num;
            var bus = data?.data?.data[i]?.bus_num;
            var bus_num = bus;


            //new driver id
            var driver_id = this.state.AllLocations[i]?.driver_id;
            var driver = data?.data?.data[i]?.driver_id;
            var driver_id = driver;


            //new location id
            var location_id = this.state.AllLocations[i]?.location_id;
            var locationID = data?.data?.data[i]?.location_id;
            var location_id = locationID;


            AllLocations.push({
              progress:[],
              path:path,
              currentLocation:currentLocation,
              location:location,
              bus_num:bus_num,
              driver_id:driver_id,
              location_id:location_id,
            });

          };


          console.log("currentLocation",AllLocations);
          this.setState({ AllLocations });


          this.setState({firstTime:false});
        }
        this.setState({load:true});
      }
    } catch (error) {
      console.log(error);
    }
    this.handlepaths();
  }

  print = () => {
    
    //if(this.state.AllLocations[0].path.length > 1 )
    //{
        this.moveObject();
    //}
    //console.log("path all data",this.state.path);
    //console.log("currentlocation",this.state.currentLocation);
    //console.log("progress all data",this.state.progress);
  }

  handlepaths = () => {
    var newpaths = [];
    for(var l=0;l<this.state.AllLocations.length;l++) 
    {
      var path = this.state.AllLocations[l]?.path.map((coordinates, i, array) => {
          if (i === 0) {
            return { ...coordinates, distance: 0 } // it begins here! 
          }
          const { lat: lat1, lng: lng1 } = coordinates
          const latLong1 = new window.google.maps.LatLng(lat1, lng1)
    
          const { lat: lat2, lng: lng2 } = array[0]
          const latLong2 = new window.google.maps.LatLng(lat2, lng2)
    
          // in meters:
          const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
            latLong1,
            latLong2
          )
    
          return { ...coordinates, distance }
        })
        newpaths.push(path);
      }
      for(var i=0;i<newpaths.length;i++)
      {
        this.state.AllLocations[i].path = newpaths[i];
      }
      //console.error("path data",this.state.path)
  }


  componentDidMount = () => {
    this.getLocationData();
    this.intervalTwo = window.setInterval(this.getLocationData, 1000)
    this.intervalThree = window.setInterval(this.print, 1000)
  }

  componentWillUnmount = () => {
    window.clearInterval(this.intervalTwo)
    window.clearInterval(this.intervalThree)
  }

  moveObject = () => {
    //console.log("move object executed")
    var allprogress = [];
    for(var i=0;i<this.state.AllLocations.length;i++)
    {
      const distance = this.getDistance()
      if (! distance) {
        return
      }
      //var path =  this.state.path;
  
      let progress = this.state.AllLocations[i].path.filter(coordinates => coordinates.distance < distance)

      const nextLine = this.state.AllLocations[i].path.find(coordinates => coordinates.distance > distance)

      if (! nextLine) {
        this.state.AllLocations[i].progress = progress;
        return // it's the end!
      }
      const lastLine = progress[progress.length - 1]

      const lastLineLatLng = new window.google.maps.LatLng(
        lastLine?.lat,
        lastLine?.lng
      )

      const nextLineLatLng = new window.google.maps.LatLng(
        nextLine?.lat,
        nextLine?.lng
      )

      // distance of this line 
      const totalDistance = nextLine?.distance - lastLine?.distance
      const percentage = (distance - lastLine?.distance) / totalDistance

      const position = window.google.maps.geometry.spherical.interpolate(
        lastLineLatLng,
        nextLineLatLng,
        percentage
      )
      
      //console.log("inside move object print",position);

      progress = progress.concat(position)
      allprogress.push(position);
      this.state.AllLocations[i].progress = progress;
      }
      for(var i=0;i<allprogress.length;i++)
      {
        this.state.AllLocations[i].progress = allprogress[i];
      }
    
  }

  componentWillMount = () => {
    var newPaths = [];
    for(var l=0;l<this.state.AllLocations.length;l++)
    {
      var path = this.state.AllLocations[l]?.path.map((coordinates, i, array) => {
        if (i === 0) {
          return { ...coordinates, distance: 0 } // it begins here! 
        }
        const { lat: lat1, lng: lng1 } = coordinates
        const latLong1 = new window.google.maps.LatLng(lat1, lng1)

        const { lat: lat2, lng: lng2 } = array[0]
        const latLong2 = new window.google.maps.LatLng(lat2, lng2)

        // in meters:
        const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
          latLong1,
          latLong2
        )

        return { ...coordinates, distance }
      })
      newPaths.push(path);
   
    }
    for(var i=0;i<newPaths.length;i++)
    {
      this.state.AllLocations[i].path = newPaths[i];
    }

  }

  componentDidUpdate = () => {
    for(var i=0;i<this.state.AllLocations.length;i++)
    {
      const distance = this.getDistance();
      if (!distance) {
        return;
      }

      let progress = this.state.AllLocations[i]?.path.filter(
        coordinates => coordinates.distance < distance
      );

      const nextLine = this.state.AllLocations[i]?.path.find(
        coordinates => coordinates.distance > distance
      );

      let point1, point2;

      if (nextLine) {
        point1 = progress[progress.length - 1];
        point2 = nextLine;
      } else {
        // it's the end, so use the latest 2
        point1 = progress[progress.length - 2];
        point2 = progress[progress.length - 1];
      }

      const point1LatLng = new window.google.maps.LatLng(point1?.lat, point1?.lng);
      const point2LatLng = new window.google.maps.LatLng(point2?.lat, point2?.lng);

      const angle = window.google.maps.geometry.spherical.computeHeading(
        point1LatLng,
        point2LatLng
      );
      const actualAngle = angle - 90;
      //console.log("actual angle",actualAngle);
      const markerUrl =
        "https://api.mylocator.net:5443/assets/bus-icon.png";
      const marker = document.querySelector(`[src="${markerUrl}"]`);

      if (marker) {
        // when it hasn't loaded, it's null
        marker.style.transform = `rotate(${actualAngle}deg)`;
      }
      console.log("AllLocations ", i,this.state.AllLocations[i]);
    }
  };

  render = () => {
    const icon = {
      url:
        "https://api.mylocator.net:5443/assets/bus-icon.png",
      scaledSize: new window.google.maps.Size(48, 48),
      anchor: { x: 10, y: 10 }
    };

    return (
    
      <div>
        <center>
        {this.state.load ? <GoogleMap
          defaultZoom={8}
          center={{
            lat: 7.2906,
            lng: 80.6337
          }}
          >
            
            { 
            this.state.AllLocations.map((bus)=>{
            return (
                <Marker 
                icon={icon}
                position={bus.progress[bus.progress.length - 1]} 
                //onClick={()=>this.openLocationInfoDetails()} 
                />
            )
            })
          }
            
        

            {/* {this.state.openLocationInfo? 
              <InfoWindow
              animation={2}
              position={this.state.progress[this.state.progress.length - 1]}
              onCloseClick={()=>this.closeLocationInfoDetails()}                                         
              >
                <div>
                    <br/>
                    <label style={{fontSize:"15px",color:"purple"}}><b>Bus Number : {this.state.bus_num}</b></label>
                    <br/>
                    <label style={{fontSize:"15px",color:"purple"}}><b>Driver ID : {this.state.driver_id}</b></label>
                    <br/>
                </div>

            </InfoWindow>
            :
              <></>} */}
          
        </GoogleMap> : ""}
        </center>
        
      </div>
    )
  }
}

const MapComponent = withScriptjs(withGoogleMap(ViewAllbusesClassMap))

export default () => (
  
  <div>
    <center>
       <div id='maindiv'>
    
        <div>
            <Label style={{fontSize:"25px",color:"black" , float:"left"}}><b>All Bus Locations</b> </Label>
            <Label style={{fontSize:"25px",color:"black"  , float:"right"}}><b>As of: {moment(new Date()).format(" MM-DD-YYYY ")} {moment(new Date()).format(" h:mm a ")}</b></Label>     
        </div>
        <br/><br/>
        <div>
            <MapComponent
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC9SZJFT_Su-uYTVlQv7ja1mkUatO444Bo&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` , width: '100%'}} />}
            containerElement={<div style={{ height: `800px`, width: '100%' }} />}
            mapElement={<div style={{ height: `100%`, width: '100%' }} />}
            /> 
        </div>
       </div> 
    </center> 
 </div>
)


// var AllLocations = [];
// for(var i=0;i<data?.data?.data?.length;i++)
// {
//   //new paths
//   var newPath = this.state.AllLocations[i]?.path;
//   var locat = [];
//   if(this.state.AllLocations[i]?.currentLocation?.lat != data?.data?.data[i]?.latitude)
//     {
//         locat.push({ 
//             lat:parseFloat(data?.data?.data[i]?.latitude),
//             lng:parseFloat(data?.data?.data[i]?.longitude),
//         });

//     }
//   var path = newPath.concat(locat);


//   //new current location
//   var curenntloc = this.state.AllLocations[i]?.currentLocation;
//   if(this.state.AllLocations[i]?.currentLocation?.lat != data?.data?.data[i]?.latitude)
//     {
//       var curntloc = {
//         lat:parseFloat(data?.data?.data[i]?.latitude),
//         lng:parseFloat(data?.data?.data[i]?.longitude),
//       };
//     }
//   var currentLocation = curenntloc.concat(curntloc);

//   //new location
//   var location = this.state.AllLocations[i]?.location;
//   var loc = {
//     lat:parseFloat(data?.data?.data[i]?.latitude),
//     lng:parseFloat(data?.data?.data[i]?.longitude),
//   };
//   var location = location.concat(loc);


//   //new bus num
//   var bus_num = this.state.AllLocations[i]?.bus_num;
//   var bus = data?.data?.data[i]?.bus_num;
//   var bus_num = bus;


//   //new driver id
//   var driver_id = this.state.AllLocations[i]?.driver_id;
//   var driver = data?.data?.data[i]?.driver_id;
//   var driver_id = driver;


//   //new location id
//   var location_id = this.state.AllLocations[i]?.location_id;
//   var location = data?.data?.data[i]?.location_id;
//   var location_id = location;


//   AllLocations.push({
//     progress:[],
//     path:path,
//     currentLocation:currentLocation,
//     location:location,
//     bus_num:bus_num,
//     driver_id:driver_id,
//     location_id:location_id,
//   });

// };