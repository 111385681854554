
import React from 'react';
import { withGoogleMap, withScriptjs, GoogleMap, Polyline, Marker ,InfoWindow  } from 'react-google-maps'
import {Circle} from '@react-google-maps/api';
import { getLocationByDriverID } from '../services/locationServices';
import {
    Modal,
    ModalBody,
    ModalHeader,
    Label
} from "reactstrap"
import moment from 'moment';
import './responsive.css';
import NavBar from './NavBar';
import Footer from './Footer';

class ClassMap extends React.Component {

  // constructor(props) {
  //   super(props);
  //   this.getCurrentUserLocation = this.getCurrentUserLocation.bind(this);
  // }

  state = {
    progress: [], 
    path : [
        // {lat:6.5854,lng:79.9607},
        // {lat:6.5854,lng:79.9607},
        // {lat: 6.586519,lng:  79.959773},
        // {lat:6.588802,lng: 79.959527},
        // {lat:6.592958,lng:79.959225},
    ],
    firstTime:true,
    currentLocation:{
      lat: 0, 
      lng: 0 
    },
    location:{
         lat: 0, 
         lng: 0 
    },
    userLocation:{
      lat: 0, 
      lng: 0 
    },
    // userLocation:{
    //   lat: 6.7070068, 
    //   lng: 79.9481971 
    // },
    load:false,
   // showUserLocation:false,
   // firstLoad:localStorage.getItem("firstload") === null ?  true : localStorage.getItem("firstload") ? false : true,
    openLocationInfo:false,
    openUserInfo:false,
    bus_num:"",
    driver_id:"",
  }

  //  handleShowUserLocation = (e,value)=>{
  //   e.preventDefault()
  //   this.setState({showUserLocation:value});
  //   localStorage.setItem("firstload",true);
  //   localStorage.setItem("showUserLocation",value);
  //   this.setState({firstLoad:false});
  // }

  openLocationInfoDetails = ()=>{
    this.setState({openLocationInfo:true});
  }

  openUserInfoDetails = ()=>{
    this.setState({openUserInfo:true});
  }

  closeLocationInfoDetails = ()=>{
    this.setState({openLocationInfo:false});
  }

  closeUserInfoDetails = ()=>{
    this.setState({openUserInfo:false});
  }

  velocity = 100
  initialDate = new Date()

  getDistance = () => {
    // seconds between when the component loaded and now
    const differentInTime = (new Date() - this.initialDate) / 1000 // pass to seconds
    return differentInTime * this.velocity // d = v*t -- thanks Newton!
  }

   getLocationData = async ()=>{
    try {
      //geting the ID from the URL
      var id= window.location.pathname;
      id = id.toString();
      var newID = id.slice(7);

      //console.error("ID",newID);
      const data = await getLocationByDriverID(newID);
      //console.log("data",data);
      if(data?.data?.status == 1)
      {
        if(this.state.currentLocation.lat != data?.data?.data[0].latitude)
        {
            //console.log("current path and fecth path" , this.state.currentLocation.lat , data?.data?.data[0].latitude)
            var newPath = this.state.path;
            var locat = { 
                lat:parseFloat(data?.data?.data[0].latitude),
                lng:parseFloat(data?.data?.data[0].longitude)
            }
            var path = newPath.concat(locat);
            this.setState({ path });
        }
        if(this.state.firstTime)
        {
          var currentLocation = { 
            lat:parseFloat(data?.data?.data[0].latitude),
            lng:parseFloat(data?.data?.data[0].longitude)
          }
          this.setState({ currentLocation });
          this.setState({firstTime:false});
          var newPath = this.state.path;
          var locat = { 
              lat:parseFloat(data?.data?.data[0].latitude),
              lng:parseFloat(data?.data?.data[0].longitude)
          }
          var path = newPath.concat(locat);
          this.setState({ path });
        }
        if(this.state.currentLocation.lat != data?.data?.data[0].latitude)
        {
          var currentLocation = { 
            lat:parseFloat(data?.data?.data[0].latitude),
            lng:parseFloat(data?.data?.data[0].longitude)
          }
          this.setState({ currentLocation });
        }
    
        var location = { 
            lat:parseFloat(data?.data?.data[0].latitude),
            lng:parseFloat(data?.data?.data[0].longitude)
        }
        this.setState({ location });
        this.setState({load:true});
        localStorage.setItem("bus_num",data?.data?.data[0].bus_num);
        var bus_num = data?.data?.data[0].bus_num;
        this.setState({ bus_num });
        var driver_id = data?.data?.data[0].driver_id;
        this.setState({ driver_id });
      }
    } catch (error) {
      console.log(error);
    }
    this.handlepaths();
  }

  print = () => {
    if(this.state.path.length > 1 )
    {
        this.moveObject();
    }
    //console.log("path all data",this.state.path);
    //console.log("currentlocation",this.state.currentLocation);
    //console.log("progress all data",this.state.progress);
  }

  handlepaths = () => {
    this.state.path = this.state.path.map((coordinates, i, array) => {
        if (i === 0) {
          return { ...coordinates, distance: 0 } // it begins here! 
        }
        const { lat: lat1, lng: lng1 } = coordinates
        const latLong1 = new window.google.maps.LatLng(lat1, lng1)
  
        const { lat: lat2, lng: lng2 } = array[0]
        const latLong2 = new window.google.maps.LatLng(lat2, lng2)
  
        // in meters:
        const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
          latLong1,
          latLong2
        )
  
        return { ...coordinates, distance }
      })
  
      //console.error("path data",this.state.path)
  }


  getCurrentUserLocation = () => { 
    //console.error('Geolocation executed');
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((result)=>{
          if (result.state === "granted") {
            console.log("permission",result.state);
            //If granted then you can directly call your function here
             navigator.geolocation.getCurrentPosition((position) => {
              //console.log("userlocation",position.coords);
              var userLocation = { 
                    lat:parseFloat(position.coords.latitude),
                    lng:parseFloat(position.coords.longitude)
                };
              
                this.setState({ userLocation });
               // console.log("goe locations",position);
            }, () => {
              console.error('Unable to retrieve your location');
            });
          } else if (result.state === "prompt") {
            console.log("permission",result.state);
          } else if (result.state === "denied") {
            //If denied then you have to show instructions to enable location
            console.log("permission",result.state);
          }
          result.onchange = function () {
            console.log("permission",result.state);
          };
        });
    } else {
      alert("Sorry Location Not available!");
    } 
   }

  componentDidMount = () => {
    this.getLocationData();
    //this.getCurrentUserLocation();
    this.intervalTwo = window.setInterval(this.getLocationData, 1000)
    this.intervalFour = window.setInterval(this.getCurrentUserLocation, 1000)
    this.intervalThree = window.setInterval(this.print, 1000)
  }

  componentWillUnmount = () => {
    window.clearInterval(this.intervalTwo)
    window.clearInterval(this.intervalFour)
    window.clearInterval(this.intervalThree)
  }

  moveObject = () => {
    //console.log("move object executed")
    const distance = this.getDistance()
    if (! distance) {
      return
    }
    //var path =  this.state.path;
 
    let progress = this.state.path.filter(coordinates => coordinates.distance < distance)

    const nextLine = this.state.path.find(coordinates => coordinates.distance > distance)

    if (! nextLine) {
      this.setState({ progress })
      return // it's the end!
    }
    const lastLine = progress[progress.length - 1]

    const lastLineLatLng = new window.google.maps.LatLng(
      lastLine?.lat,
      lastLine?.lng
    )

    const nextLineLatLng = new window.google.maps.LatLng(
      nextLine?.lat,
      nextLine?.lng
    )

    // distance of this line 
    const totalDistance = nextLine?.distance - lastLine?.distance
    const percentage = (distance - lastLine?.distance) / totalDistance

    const position = window.google.maps.geometry.spherical.interpolate(
      lastLineLatLng,
      nextLineLatLng,
      percentage
    )
    
    //console.log("inside move object print",position);

    progress = progress.concat(position)
    this.setState({ progress })
  }

  componentWillMount = () => {
    this.state.path = this.state.path.map((coordinates, i, array) => {
      if (i === 0) {
        return { ...coordinates, distance: 0 } // it begins here! 
      }
      const { lat: lat1, lng: lng1 } = coordinates
      const latLong1 = new window.google.maps.LatLng(lat1, lng1)

      const { lat: lat2, lng: lng2 } = array[0]
      const latLong2 = new window.google.maps.LatLng(lat2, lng2)

      // in meters:
      const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
        latLong1,
        latLong2
      )

      return { ...coordinates, distance }
    })

    console.error("path data",this.state.path)
  }

  componentDidUpdate = () => {
    const distance = this.getDistance();
    if (!distance) {
      return;
    }

    let progress = this.state.path.filter(
      coordinates => coordinates.distance < distance
    );

    const nextLine = this.state.path.find(
      coordinates => coordinates.distance > distance
    );

    let point1, point2;

    if (nextLine) {
      point1 = progress[progress.length - 1];
      point2 = nextLine;
    } else {
      // it's the end, so use the latest 2
      point1 = progress[progress.length - 2];
      point2 = progress[progress.length - 1];
    }

    const point1LatLng = new window.google.maps.LatLng(point1?.lat, point1?.lng);
    const point2LatLng = new window.google.maps.LatLng(point2?.lat, point2?.lng);

    const angle = window.google.maps.geometry.spherical.computeHeading(
      point1LatLng,
      point2LatLng
    );
    const actualAngle = angle - 90;
    //console.log("actual angle",actualAngle);
    const markerUrl =
      "https://api.mylocator.net:5443/assets/bus-icon.png";
    const marker = document.querySelector(`[src="${markerUrl}"]`);

    if (marker) {
      // when it hasn't loaded, it's null
      marker.style.transform = `rotate(${actualAngle}deg)`;
    }
  };

  render = () => {
    // const icon = {
    //   url: (require('./images/bus-icon.png')),
    //   scaledSize: new window.google.maps.Size(64, 64),
    //   anchor: { x: 10, y: 10 },
    // }
    const icon = {
      url:
        "https://api.mylocator.net:5443/assets/bus-icon.png",
      scaledSize: new window.google.maps.Size(48, 48),
      anchor: { x: 10, y: 10 }
    };
    const UserIcon = {
      url:
        "https://static.thenounproject.com/png/1608718-200.png",
      scaledSize: new window.google.maps.Size(32, 32),
      anchor: { x: 10, y: 10 }
    };
    const options = {
      strokeColor: '#1344e8',
      strokeOpacity: 0.8,
      strokeWeight: 1,
      fillColor: '#1344e8',
      fillOpacity: 0.35,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      radius: 300,
      zIndex: 1
    }
    return (
    
      <div>
        <center>
        {this.state.load ? <GoogleMap
          defaultZoom={16}
          center={this.state.currentLocation}
          >
            { this.state.progress && (
              <>
                {/* <Polyline path={this.state.progress} options={{ strokeColor: "#FF0000 "}} /> */}
                <Marker 
                icon={icon}
                position={this.state.progress[this.state.progress.length - 1]} 
                onClick={()=>this.openLocationInfoDetails()} />
              </>
            )}
            
            {/* display user location */}
            <Marker
              icon={UserIcon}
              position={this.state.userLocation} 
              onClick={()=>this.openUserInfoDetails()} />
            <Circle
                position={this.state.userLocation}
                options={options} 
                />
        

            {this.state.openLocationInfo? 
              <InfoWindow
              animation={2}
              position={this.state.progress[this.state.progress.length - 1]}
              onCloseClick={()=>this.closeLocationInfoDetails()}                                         
              >
                <div>
                    <br/>
                    <label style={{fontSize:"15px",color:"purple"}}><b>Bus Number : {this.state.bus_num}</b></label>
                    <br/>
                    <label style={{fontSize:"15px",color:"purple"}}><b>Driver ID : {this.state.driver_id}</b></label>
                    <br/>
                </div>

            </InfoWindow>
            :
              <></>}
            {this.state.openUserInfo ? 
              <InfoWindow
                animation={2}
                position={this.state.userLocation}
                onCloseClick={()=>this.closeUserInfoDetails()}                                         
                >
                  <div>
                    <br/>
                    <label style={{fontSize:"15px",color:"purple"}}><b>{"User Location"}</b></label>
                    <br/>
                  </div>

              </InfoWindow>
            :
              <></>
            }

          
        </GoogleMap> : ""}
        </center>
        
        {/* <div>
          
        <Modal
          isOpen={this.state.firstLoad}
          className="modal-dialog-centered"
          fade={true}
          backdrop={true}
        >
          <ModalHeader
            toggle={() => {
              this.setState({firstLoad:false});;
            }}
          >
            <Label>Enter Details</Label>
          </ModalHeader>
          <ModalBody>
            <div >
                    <center>
                    <label style={{fontSize:"30px",color:"purple"}}>Want to show your location on map?</label>
                    <br/><br/>
                    <button className='btn btn-success' style={{width:"100px",height:"50px"}} onClick={(e)=>this.handleShowUserLocation(e,true)}>Yes</button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button className='btn btn-danger'style={{width:"100px",height:"50px"}} onClick={(e)=>this.handleShowUserLocation(e,false)}>No</button>
                    </center>
                </div>
          </ModalBody>
        </Modal>
        </div> */}

      </div>
    )
  }
}

const MapComponent = withScriptjs(withGoogleMap(ClassMap))

export default () => (
  
  <div>
    <NavBar/>
    <center>
       <div id='maindiv'>
    
        <div>
            <Label style={{fontSize:"25px",color:"black" , float:"left"}}><b>Vehicle Number : {localStorage.getItem("bus_num")}</b> </Label>
            <Label style={{fontSize:"25px",color:"black"  , float:"right"}}><b>As of: {moment(new Date()).format(" MM-DD-YYYY ")} {moment(new Date()).format(" h:mm a ")}</b></Label>     
        </div>
        <br/><br/>
        <div>
            <MapComponent
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC9SZJFT_Su-uYTVlQv7ja1mkUatO444Bo&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` , width: '100%'}} />}
            containerElement={<div style={{ height: `800px`, width: '100%' }} />}
            mapElement={<div style={{ height: `100%`, width: '100%' }} />}
            /> 
        </div>
       </div> 
    </center> 
    <Footer/>
 </div>
)