import { useCallback, useEffect, useState } from "react";
import Maps, { computeDistance, interpolate } from "react-maps-suite";
import { getLocationByDriverID } from '../services/locationServices';
import { useParams} from 'react-router-dom';
import moment from 'moment';
import {
    Modal,
    ModalBody,
    ModalHeader,
    Label
} from "reactstrap";
import "./responsive.css"

const defaultCenter = { lat: 6.7106, lng: 79.9074 }

const defaultZoom = 14;



const DEFAULT_SPEED = 5; // m/s

function FuncMap() {


    const defaultPath = [
        { lat: 6.712130685669934, lng: 79.90959444020194 },
        { lat: 6.712313906985205, lng: 79.90881257027479 },
        { lat: 6.712357531169066, lng: 79.90815369123567},
        { lat: 6.712401155348982, lng: 79.9076792981935 },
        { lat: 6.712526665470793, lng: 79.90746081306486 },
        { lat: 6.713515996512598, lng: 79.90746967518021 },
        { lat: 6.71484069505494,  lng: 79.90757130196005 },
        { lat: 6.715786906091805, lng: 79.90750778546493 },
        { lat: 6.716480793016085, lng: 79.90768563184508 },
        { lat: 6.717452233143757, lng: 79.90771103805548 },
        { lat: 6.718313749554285, lng: 79.90770078173495 },
        { lat: 6.719341796126784, lng: 79.90760176628714 },
        { lat: 6.720826343495209, lng: 79.90740376747459 },
        { lat: 6.7218949795624185,lng: 79.90728047151458},
    ].reduce((result, item, index, array) => {
      if (index === 0) {
        result.push({ ...item, distance: 0 });
        return result;
      }
    
      const { distance: lastDistance } = result[index - 1];
      const previous = array[index - 1];
      const distance = lastDistance + computeDistance(previous, item);
    
      result.push({ ...item, distance });
      return result;
    }, []);

    const {id} = useParams();
        
    const [location, setlocation] = useState({
        lat: 0,
        lng: 0
    });
    const [bus_num, setbus_num] = useState("")
    const [load, setload] = useState(false);


    const getLocationData = async (id)=>{
        try {
          const data = await getLocationByDriverID(id);
          console.log("data",data);
          if(data?.data?.status == 1)
          {
            setlocation({ 
                lat:parseFloat(data?.data?.data[0].latitude),
                lng:parseFloat(data?.data?.data[0].longitude)
            });
            setbus_num(data?.data?.data[0].bus_num);
            setload(true);
          }
          else
          {
            setlocation({ 
              lat: 6.9271,
              lng: 79.8612
          });
            setload(false);
          }
    
        } catch (error) {
          console.log(error);
        }
      }

       //handle show user location details
  const [showUserLocation, setshowUserLocation] = useState(false);
  const [firstLoad, setfirstLoad] = useState(localStorage.getItem("firstload") === null ?  true : localStorage.getItem("firstload") ? false : true);  

  const handleShowUserLocation = (e,value)=>{
    e.preventDefault()
    setshowUserLocation(value);
    localStorage.setItem("firstload",true);
    setfirstLoad(false);
  }

   //current user location function

   const [userLocation, setuserLocation] = useState({
    lat: 0,
    lng: 0
   });


  const getCurrentUserLocation= ()=>{ 
    //console.error('Geolocation executed');
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          if (result.state === "granted") {
            console.log("permission",result.state);
            //If granted then you can directly call your function here
            navigator.geolocation.getCurrentPosition((position) => {
              setuserLocation({ 
                    lat:parseFloat(position.coords.latitude),
                    lng:parseFloat(position.coords.longitude)
                });
               // console.log("goe locations",position);
            }, () => {
              console.error('Unable to retrieve your location');
            });
          } else if (result.state === "prompt") {
            console.log("permission",result.state);
          } else if (result.state === "denied") {
            //If denied then you have to show instructions to enable location
            console.log("permission",result.state);
          }
          result.onchange = function () {
            console.log("permission",result.state);
          };
        });
    } else {
      alert("Sorry Location Not available!");
    }
  
   }

   const executeOrder = ()=>{
    if(showUserLocation) 
    { 
      getCurrentUserLocation();
     // console.log("param data ",id);
      getLocationData(id);
    } 
    else
    {
      //console.log("param data ",id);
      getLocationData(id);
    }
   }

  useEffect(()=>{
    executeOrder();
  },[showUserLocation])

  useEffect(()=>{
    console.log("DB fetched locations",location)
  },[location])


  useEffect(()=>{
    setInterval(function() {
      getLocationData(id);
    }, 15000);
  },[load])


    const [time, setTime] = useState(0);

    const increaseTime = useCallback(() => {
      setTime((time) => time + 1);
    }, []);
  
    useEffect(() => {
      const interval = setInterval(increaseTime, 1000);
      return () => {
        clearInterval(interval);
      };
    }, [increaseTime]);
  
    const distance = DEFAULT_SPEED * time;
  
    const position = getPositionAt(defaultPath, distance);
  
    // const icon = {
    //   url: (require('./images/bus-icon.png')),
    //   scaledSize: new window.google.maps.Size(64, 64),
    //   anchor: { x: 10, y: 10 },
    // }

  return (
    <div>
        <center>
            <div id="maindiv">
            <br/>
                
                <div>
                      <Label style={{fontSize:"25px",color:"black" , float:"left"}}><b>Vehicle Number : {bus_num}</b> </Label>
                      <Label style={{fontSize:"25px",color:"black"  , float:"right"}}><b>As of: {moment(new Date()).format(" MM-DD-YYYY ")} {moment(new Date()).format(" h:mm a ")}</b></Label>     
                </div>
                    
                <br/>
                <br/>
                <br/>
                <Maps
                    provider="google"
                    googleLoaderOptions={{ apiKey: 'AIzaSyAkBhTU6Tc8FNdu64ZRG4rPm2bin7H7OOI', libraries: ['geometry']}}
                    height={800}
                    defaultCenter={defaultCenter}
                    defaultZoom={defaultZoom}
                >
                    <Maps.Polyline path={defaultPath} 
                        fillColor="red"
                        strokeColor="red"
                        strokeWeight={5} 
                    />
                    <Maps.Marker 
                        position={position} 
                        imageUrl="https://cdn.pixabay.com/photo/2014/04/03/10/31/bus-310766_640.png"
                        size={{width:64, height:64}}
                        anchor={{x:20,y:20}}
                    />

                    <Maps.Marker 
                        position={userLocation} 
                        imageUrl={(require("../assets/images/iconman.png"))}
                        size={{width:64, height:64}}
                        anchor={{x:20,y:20}}
                    />
                    

                </Maps>
            </div>
        </center>
        
        <Modal
          isOpen={firstLoad}
          className="modal-dialog-centered"
          fade={true}
          backdrop={true}
        >
          <ModalHeader
            toggle={() => {
              setfirstLoad(false);
            }}
          >
            <Label>Enter Details</Label>
          </ModalHeader>
          <ModalBody>
            <div >
                    <center>
                    <label style={{fontSize:"30px",color:"purple"}}>Want to show your location on map?</label>
                    <br/><br/>
                    <button className='btn btn-success' style={{width:"100px",height:"50px"}} onClick={(e)=>handleShowUserLocation(e,true)}>Yes</button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button className='btn btn-danger'style={{width:"100px",height:"50px"}} onClick={(e)=>handleShowUserLocation(e,false)}>No</button>
                    </center>
                </div>
          </ModalBody>
        </Modal>
  




    </div>
    
  );
}


function getPositionAt(path, distance) {
    const indexesPassed = path.filter((position) => position.distance < distance);
    if (indexesPassed.length === 0) {
      return path[0]; // starting position
    }
  
    const lastIndexPassed = indexesPassed.length - 1;
    const nextIndexToPass = lastIndexPassed + 1;
  
    const lastPosition = path[lastIndexPassed];
    const nextPosition = path[nextIndexToPass];
  
    if (!nextPosition) {
      return lastPosition; // distance is greater than the ones we have in the array
    }
  
    const progressUntilNext =
      (distance - lastPosition.distance) / nextPosition.distance;
  
    const currentPosition = interpolate(
      lastPosition,
      nextPosition,
      progressUntilNext
    );
  
    return currentPosition;
  }
  

export default FuncMap;
