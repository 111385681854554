import React,{useEffect} from "react";
import {BrowserRouter, BrowserRouter as Router , Route , Routes} from "react-router-dom";
import './App.css';
import Map from "./components/Map";
import Home from "./components/Home";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import MapComponent from "./components/MapComponent";
import FuncMap from "./components/FuncMap";
import ClassMap from "./components/ClassMap";
import ViewAllBuses from "./components/ViewAllBuses";
import ViewAllbusesClassMap from "./components/ViewAllbusesClassMap";

function App() {

  const path = window.location.pathname;

  useEffect(()=>{
    if(path == "/")
    {
      localStorage.removeItem("firstload");
    }
  },[])

  return (
    <div className="App">
      <Router>
        <Routes>
            <Route exact path="/" element={<Home/>}/>
            {/* <Route exact path="/track/:id" element={<MapComponent/>}/> */}
            {/* <Route exact path="/track/:id" element={<Map/>}/> */}
            {/* <Route exact path="/track/:id" element={<FuncMap/>}/> */}
            <Route exact path="/track/:id" element={<ClassMap/>}/>
            <Route exact path="/sltb/bus-tracker/all" element={<ViewAllBuses/>}/>
            {/* <Route exact path="/sltb/bus-tracker/all-class-map" element={<ViewAllbusesClassMap/>}/> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
