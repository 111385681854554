
import React from 'react';
import { withGoogleMap, withScriptjs, GoogleMap, Polyline, Marker } from 'react-google-maps'

class NewMap extends React.Component {
  constructor(props) {
    super(props);
    console.error("AllLocation data",props); // “AllLocations”
  }
  state = {
    progress: [],
  }

  path = [
    { lat: 6.7218949795624185, lng: 79.90728047151458},
    { lat: 6.720826343495209, lng:  79.90740376747459 },
    { lat: 6.719341796126784, lng: 79.90760176628714 },
    { lat: 6.718313749554285, lng: 79.90770078173495 },
    { lat: 6.717452233143757, lng: 79.90771103805548 },
    { lat: 6.716480793016085, lng: 79.90768563184508 },
    { lat: 6.715786906091805, lng: 79.90750778546493 },
    { lat: 6.71484069505494, lng: 79.90757130196005 },
    { lat: 6.713515996512598, lng: 79.90746967518021 },
    { lat: 6.712526665470793, lng: 79.90746081306486 },
    { lat: 6.712401155348982, lng: 79.9076792981935 },
    { lat: 6.712357531169066, lng: 79.90815369123567},
    { lat: 6.712313906985205, lng: 79.90881257027479 },
    { lat: 6.712130685669934, lng: 79.90959444020194 },

  ]



  //path = this.props.AllLocations;
 
  
  velocity = 100
  initialDate = new Date()

  getDistance = () => {
    // seconds between when the component loaded and now
    const differentInTime = (new Date() - this.initialDate) / 1000 // pass to seconds
    return differentInTime * this.velocity // d = v*t -- thanks Newton!
  }

  componentDidMount = () => {
    this.interval = window.setInterval(this.moveObject, 1000)
  }

  componentWillUnmount = () => {
    window.clearInterval(this.interval)
  }

  moveObject = () => {
    const distance = this.getDistance()
    if (! distance) {
      return
    }

    let progress = this.path.filter(coordinates => coordinates.distance < distance)

    const nextLine = this.path.find(coordinates => coordinates.distance > distance)
    if (! nextLine) {
      this.setState({ progress })
      return // it's the end!
    }
    const lastLine = progress[progress.length - 1]

    const lastLineLatLng = new window.google.maps.LatLng(
      lastLine.lat,
      lastLine.lng
    )

    const nextLineLatLng = new window.google.maps.LatLng(
      nextLine.lat,
      nextLine.lng
    )

    // distance of this line 
    const totalDistance = nextLine.distance - lastLine.distance
    const percentage = (distance - lastLine.distance) / totalDistance

    const position = window.google.maps.geometry.spherical.interpolate(
      lastLineLatLng,
      nextLineLatLng,
      percentage
    )

    progress = progress.concat(position)
    this.setState({ progress })
  }

  componentWillMount = () => {
    this.path = this.path.map((coordinates, i, array) => {
      if (i === 0) {
        return { ...coordinates, distance: 0 } // it begins here! 
      }
      const { lat: lat1, lng: lng1 } = coordinates
      const latLong1 = new window.google.maps.LatLng(lat1, lng1)

      const { lat: lat2, lng: lng2 } = array[0]
      const latLong2 = new window.google.maps.LatLng(lat2, lng2)

      // in meters:
      const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
        latLong1,
        latLong2
      )

      return { ...coordinates, distance }
    })

    console.log(this.path)
  }

  componentDidUpdate = () => {
    const distance = this.getDistance();
    if (!distance) {
      return;
    }

    let progress = this.path.filter(
      coordinates => coordinates.distance < distance
    );

    const nextLine = this.path.find(
      coordinates => coordinates.distance > distance
    );

    let point1, point2;

    if (nextLine) {
      point1 = progress[progress.length - 1];
      point2 = nextLine;
    } else {
      // it's the end, so use the latest 2
      point1 = progress[progress.length - 2];
      point2 = progress[progress.length - 1];
    }

    const point1LatLng = new window.google.maps.LatLng(point1.lat, point1.lng);
    const point2LatLng = new window.google.maps.LatLng(point2.lat, point2.lng);

    const angle = window.google.maps.geometry.spherical.computeHeading(
      point1LatLng,
      point2LatLng
    );
    const actualAngle = angle - 90;

    const markerUrl =
      "https://cdn.pixabay.com/photo/2014/04/03/10/31/bus-310766_640.png";
    const marker = document.querySelector(`[src="${markerUrl}"]`);

    if (marker) {
      // when it hasn't loaded, it's null
      marker.style.transform = `rotate(${actualAngle}deg)`;
    }
  };

  render = () => {
    // const icon = {
    //   url: (require('./images/bus-icon.png')),
    //   scaledSize: new window.google.maps.Size(64, 64),
    //   anchor: { x: 10, y: 10 },
    // }
    const icon = {
      url:
        "https://cdn.pixabay.com/photo/2014/04/03/10/31/bus-310766_640.png",
      scaledSize: new window.google.maps.Size(32, 32),
      anchor: { x: 10, y: 10 }
    };
    return (
    
      <div>
        <GoogleMap
          defaultZoom={16}
          defaultCenter={{ lat: 6.7106, lng: 79.9074 }}
          >
            { this.state.progress && (
              <>
                <Polyline path={this.state.progress} options={{ strokeColor: "#FF0000 "}} />
                <Marker 
                icon={icon}
                position={this.state.progress[this.state.progress.length - 1]} />
              </>
            )}
          
        </GoogleMap>
      </div>
    )
  }
}

const MapComponent = withScriptjs(withGoogleMap(NewMap))

export default () => (
  <MapComponent
  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC9SZJFT_Su-uYTVlQv7ja1mkUatO444Bo&libraries=geometry,drawing,places"
  loadingElement={<div style={{ height: `100%` }} />}
  containerElement={<div style={{ height: `800px`, width: '100%' }} />}
  mapElement={<div style={{ height: `100%` }} />}
  />
)